import { Injectable } from "@angular/core";
import { GoogleUserService } from "./google-user.service";
import { signOut } from "@angular/fire/auth";

@Injectable({
	providedIn: "root",
})
export class SignOutService {
	constructor(private readonly googleUser: GoogleUserService) {}

	signOut() {
		console.trace("sing out call");
		return signOut(this.googleUser.auth);
	}
}
